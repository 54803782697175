var bcBack = (function () {
	'use strict';

	//Load public functions
	// var ajaxRequest = crypt.ajaxRequest; //ajaxRequest(file, success, error)

	//Declare globals
	var userTokenMap = {};
	// var loginURL = '/php/basecamp/oauth.php';
	var queryURL = 'php/basecamp/basecamp.php';
	var basecampProduct = 'bc3';
	var baseURL = _CONFIG.DBK_BASEURL || `//${window.location.host}/`;

	var settings = {
		config: {
			immediate: true, // set to true so we attempt to retrieve token before auth window
		},
	};

	var projects;
	var user;
	var people;

	var showMessage;

	var rateLimitReached;

	var eventCache;
	var eventsPromise;

	var errorReporter;

	return {
		clearTokenMemory: clearTokenMemory,
		setErrorReporter: setErrorReporter,
		auth: auth,
		// initialize: initialize,
		// login: login,
		deauthorize: deauthorize,
		retrieveOauthPayload: retrieveOauthPayload,
		getUserProfile: getUserProfile,
		getPeople: getPeople,
		getSchedules: getSchedules,
		updateProject: updateProject,
		getStoredLists: getStoredLists,
		getTodoLists: getTodoLists,
		getEvents: getEvents,
		createEvent: createEvent,
		updateEvent: updateEvent,
		deleteEvent: deleteEvent,
		clearEventCache: clearEventCache,
		setTodoCompletedValue: setTodoCompletedValue,
	};

	//Public Functions

	function clearTokenMemory() {
		settings.token = null;
		settings.config.immediate = false;
		user = null;
		userTokenMap = {};
	}

	function setErrorReporter(errorReportingFunction) {
		errorReporter = errorReportingFunction;
	}

	function getUserProfile() {
		return settings.myInfo;
	}

	function isSalesforce() {
		return fbk.isSalesforce();
	}

	//Google Authorization routine.
	//Try to get the token silently, if that fails then callback should present button for auth - statusOnly is boolean to avoid an attempt to sign in
	function auth(
		userID,
		sourceID,
		statusOnly,
		forceConsent,
		redirectAuth,
		redirectAuthFunction,
		callback
	) {
		// var forceRedirect = (redirectAuth || isStandalone()) && !isSalesforce();
		var forceRedirect = redirectAuth && !isSalesforce();
		var salesforceMobileRedirect = isMobile() && isSalesforce();

		if (statusOnly) {
			settings.config.immediate = true;
		}

		if (forceConsent) {
			settings.config.immediate = false;
		}

		if (!settings.config.immediate && forceRedirect) {
			authRedirect(sourceID, userID, function (result) {
				check(result, callback);
			});
		}
		// else if (!settings.config.immediate && salesforceMobileRedirect) {
		//Enable this if there is no way to tell the difference between the SF mobile app and the SF1 Web page
		// 	salesforceMobileAuthRedirect(sourceID, userID, callBack);
		// }
		else if (!settings.config.immediate) {
			authPopup(sourceID, userID, function (result) {
				check(result, callback);
			});
		} else if (!settings.token) {
			// Attempt to get new token from server
			updateToken(userID, sourceID, function (result) {
				check(result, callback);
			});
		} else {
			callback(true);
		}

		function authRedirect(sourceID, userID, callback) {
			var currentURL = window.location.href;
			var redirectURL =
				baseURL +
				'api/basecamp/auth?sourceID=' +
				encodeURIComponent(sourceID) +
				'&userID=' +
				encodeURIComponent(userID) +
				'&clientRedirectURL=' +
				encodeURIComponent(currentURL);

			if (redirectAuthFunction) {
				redirectURL += '&type=redirectFunction';
				redirectAuthFunction(redirectURL, function () {
					// Wrapped in a timeout because sometimes the read from firebase happens before the data is available.
					// Todo figure out a better way than a delay here to ensure the token is ready to be fetched
					window.setTimeout(() => {
						updateToken(userID, sourceID, function (result) {
							check(result, function (result) {
								callback(result);
							});
						});
					}, 500);
				});
			} else {
				redirectURL += '&type=redirect';
				window.location.href = redirectURL;
			}
		}

		function authPopup(sourceID, userID, callback) {
			var popup = window.open(
				baseURL +
					'api/basecamp/auth?type=popup&sourceID=' +
					encodeURIComponent(sourceID) +
					'&userID=' +
					encodeURIComponent(userID),
				'',
				'width=500, height=720'
			);
			popup.onload = function () {
				// Check for an error loading the url
				var documentTitle = popup.document.title;
				if (documentTitle === '502 Bad Gateway') {
					popup.close();
					errorReporter(502);
				}
			};

			popoverCheck(popup, callback);

			function popoverCheck(popupWindow, callback) {
				window.setTimeout(function () {
					var urlParams = {};
					var returnParams = {};
					try {
						urlParams = getURLParams(popupWindow.location.search);
					} catch (error) {
						// Couldn't get url, probably because the popup is currenty showing a google domain
						// Nothing to do here as we will wait for further action either window close or redirect back to our domain
					}
					if (popupWindow && popupWindow.closed) {
						// If window was closed run the callback with no result
						callback();
					} else if (urlParams.access_token) {
						returnParams.access_token = urlParams.access_token;
						returnParams.expiry_date =
							new Date().getTime() +
							Number(urlParams.expires_in) * 1000;

						popupWindow.close();

						applyToken(userID, sourceID, returnParams, null);

						callback(returnParams);
					} else {
						popoverCheck(popupWindow, callback);
					}
				}, 250);
			}
		}

		function salesforceMobileAuthRedirect(sourceID, userID, callback) {
			var hidden = false;
			var url =
				baseURL +
				'api/basecamp/auth?type=popup&sourceID=' +
				encodeURIComponent(sourceID) +
				'&userID=' +
				encodeURIComponent(userID) +
				'&forceClose=true';

			//Add a listener for popup window closing
			document.addEventListener('visibilitychange', onchange);

			//Open new auth window
			fbk.publish('dbk.navigate', {url: url, new: true});

			function onchange(e) {
				hidden = !hidden;
				if (!hidden) {
					document.removeEventListener('visibilitychange', onchange);
					auth(userID, sourceID, true, false, callback);
				}
			}
		}
	}

	function updateToken(userID, sourceID, callback) {
		var params = {
			userID: userID,
			sourceID: sourceID,
		};

		ajaxRequest({
			url: baseURL + 'api/basecamp/token',
			type: 'GET',
			params: params,
			onSuccess: function (tokens) {
				applyToken(userID, sourceID, tokens, callback);
			},
			onError: function (error) {
				console.log(error);
				if (callback) {
					callback(false);
				}
			},
		});
	}

	function applyToken(userID, sourceID, tokens, callback) {
		var fetchMinutesBeforeExpires = 15;
		var now = new Date().getTime();

		var token;
		var expires;

		var updateTimeout;

		if (tokens && !tokens.expiry_date && tokens.expires_in) {
			tokens.expiry_date =
				new Date().getTime() + Number(tokens.expires_in) * 1000;
		}

		if (
			tokens &&
			tokens.access_token &&
			tokens.expiry_date &&
			!tokens.error
		) {
			token = tokens.access_token;
			expires = Number(tokens.expiry_date);

			updateTimeout =
				expires - now - 1000 * 60 * fetchMinutesBeforeExpires;

			// Remove old token from user token map if it exists
			if (userTokenMap[settings.token]) {
				delete userTokenMap[settings.token];
			}

			// Set the token to global settings
			settings.token = token;
			settings.tokenexpires = now + (expires - 60) * 1000;

			userTokenMap[token] = {
				token: token,
				expires: expires,
				userID: userID,
				sourceID: sourceID,
			};

			settings.config.immediate = true;

			// Set a timeout so we can update the token before it expires
			window.setTimeout(function () {
				updateToken(userID, sourceID);
			}, updateTimeout);
		} else {
			// Set to true here as we'll be recalling without re-loading the page
			settings.config.immediate = true;
		}

		if (callback) {
			callback(token);
		}
	}

	// callback for checking our silent try
	function check(result, callback) {
		if (result && !result.error) {
			// we're already authorized in.
			getAuthorize(callback);
		} else {
			//set settings.config.immediate to false so next check brings up the pop-over
			//false callBack should trigger showing the auth button
			settings.config.immediate = false;
			if (callback) {
				callback(false);
			}

			return false;
		}
	}

	function deauthorize(userID, sourceID, switchAccount, callback) {
		var params = {
			userID: userID,
			sourceID: sourceID,
		};

		if (switchAccount) {
			processDeauthorize(callback);
			return;
		}

		ajaxRequest({
			url: baseURL + 'api/basecamp/token',
			type: 'DELETE',
			params: params,
			onSuccess: function (response) {
				processDeauthorize(callback);
			},
			onError: function (error) {
				console.log(error);
			},
		});

		function processDeauthorize(callback) {
			if (callback) {
				settings.token = null;
				user = null;
				if (!switchAccount) {
					settings.config.immediate = false;
				}
				callback();
			}
		}
	}

	// function initialize(callback, statusOnly, redirectAuth, redirectAuthFunction, accessToken, refreshToken, tokenExpires, httpsCheck, messageFunc) {
	// 	var now = new Date().getTime();
	// 	var expiresOffset = 86400000; //use our refresh token if we are within 24 hours of token expiration

	// 	showMessage = messageFunc;

	// 	settings.callback = callback;

	// 	if (refreshToken && tokenExpires <= (now - expiresOffset)) {
	// 		settings.refreshToken = refreshToken;
	// 		refreshAccessToken(retrieveOauthPayload);
	// 	}
	// 	else if (accessToken) {
	// 		settings.token = accessToken;
	// 		settings.refreshToken = refreshToken;
	// 		getAuthorize(callback);
	// 	}
	// 	else if(statusOnly) {
	// 		getAuthorize(callback);
	// 	}
	// 	else if (!refreshToken  && !statusOnly) {
	// 		httpsCheck(function() {
	// 			login(redirectAuthFunction);
	// 		});
	// 	}
	// }

	// function login(redirectAuthFunction) {
	// 	settings.window = true;
	// 	var popup;

	// 	if (redirectAuthFunction) {
	// 		redirectAuthFunction(baseURL + loginURL);
	// 	}
	// 	else {
	// 		popup = window.open(loginURL, "", "height=800,width=550,location=no", true);
	// 	}

	// 	popoverCheck(popup, retrieveOauthPayload);

	// 	function popoverCheck(popupWindow, callback) {
	// 		window.setTimeout(function() {
	// 			if (popupWindow && popupWindow.closed) {
	// 				// If window was closed run the callback with no result
	// 				window.setTimeout(function() {
	// 					if (callback && settings.window) {
	// 						callback();
	// 					}
	// 				}, 0);
	// 			}
	// 			else {
	// 				popoverCheck(popupWindow, callback);
	// 			}
	// 		}, 250);
	// 	}
	// }

	function retrieveOauthPayload(data) {
		settings.window = false;
		self.focus();

		if (!data) {
			settings.callback(null);
			return;
		}

		var result = JSON.parse(data);

		if (result.refresh_token) {
			settings.refreshToken = result.refresh_token;
		}
		if (result.access_token) {
			settings.token = result.access_token;
		}

		getAuthorize(settings.callback);
	}

	function getAuthorize(callback) {
		authorizeBasecamp(processResult);
		function processResult(result) {
			if (result && !result.errorCode) {
				if (result.expires_at) {
					settings.expiresOn = result.expires_at;
				}

				settings.account = getAccountFromResult(
					result,
					basecampProduct
				);
				settings.user = result.identity;

				getMyInfo(function (result) {
					user = result;
					settings.myInfo = user;
					checkAuthProcessCount(auth, callback);
				});

				getProjects(function (result) {
					projects = result;
					checkAuthProcessCount(auth, callback);
				});

				//We no longer get people on initialize
				// getPeople(null, function(result) {
				// 	people = result;
				// 	settings.people = people;
				// 	checkAuthProcessCount(auth, callback);
				// });
			} else {
				callback(null);
			}
		}
	}

	function checkAuthProcessCount(auth, callback) {
		var processCount = 2; //How many completed processes do we wait to complete before running the callback
		if (isNaN(Number(settings.processCount))) {
			settings.processCount = 0;
		}

		settings.processCount++;

		if (settings.processCount >= processCount) {
			settings.processCount = 0;
			if (callback) {
				callback(auth);
			}
		}
	}

	function getMyInfo(callback) {
		var query = '/my/profile.json';
		queryBasecamp(query, false, processResult);

		function processResult(result) {
			if (result) {
				callback(result);
			} else {
				callback(false);
			}
		}
	}

	function getProjects(callback) {
		var query = '/projects.json';
		queryBasecamp(query, false, processResult);

		function processResult(result) {
			if (result) {
				callback(result);
			} else {
				callback(false);
			}
		}
	}

	function getPeople(projectID, callback) {
		var query = projectID
			? '/projects/' + projectID + '/people.json'
			: '/people.json';

		queryBasecamp(query, false, processResult);

		function processResult(result) {
			var peopleResult = [];
			if (result) {
				for (var i = 0; i < result.length; i++) {
					peopleResult.push(result[i]);
				}
				if (projectID) {
					callback({projectID: projectID, people: peopleResult});
				} else {
					callback(peopleResult);
				}
			} else {
				callback(false);
			}
		}
	}

	function getSchedules(callback) {
		var schedules = [];
		for (var i = 0; i < projects.length; i++) {
			schedules.push(getScheduleFromProject(projects[i]));
		}
		callback(schedules);
	}

	function getSchedule(projectID, scheduleID, callback) {
		var query =
			'/buckets/' + projectID + '/schedules/' + scheduleID + '.json';
		queryBasecamp(query, false, processResult);

		function processResult(result) {
			if (result) {
				callback(result);
			} else {
				callback(false);
			}
		}
	}

	function getStoredLists(projectID) {
		var project = getProjectFromProjects(Number(projectID));
		if (!project) {
			return;
		}
		return project.lists;
	}

	function updateProject(projectID, editObj, callback) {
		if (!editObj || !editObj.name) {
			return;
		}

		var project;
		var path = '/projects/' + projectID + '.json';

		for (var i = 0; i < projects.length; i++) {
			//We want to check for matching ID in both string and number as basecamp stores ID's as numbers so use == instead of ===
			if (projects[i].id == projectID) {
				project = projects[i];
				break;
			}
		}

		editObj.description = project.description;

		editBasecamp(path, editObj, 'update', processResult);

		function processResult(result) {
			if (result) {
				callback(result);
			} else {
				callback(false);
			}
		}
	}

	function getTodos(projectID, initialFetchIDs, callback) {
		var projectIDString = initialFetchIDs ? initialFetchIDs : projectID;
		var projectString = '&bucket=' + projectIDString;
		var query = '/projects/recordings.json?type=Todo' + projectString;
		queryBasecamp(query, true, function (todos) {
			var todosResult = [];
			//Make sure we are working with an array if todos is not set
			if (!todos) {
				todos = [];
			}
			for (var i = 0; i < todos.length; i++) {
				var todo = todos[i];
				if (todo.due_on) {
					todosResult.push(mutateTodo(todo));
				}
			}
			if (callback) {
				callback(todosResult);
			}
		});
	}

	function getTodoLists(projectID, callback) {
		var project = getProjectFromProjects(Number(projectID));
		var todoSet = getTodoSetFromProject(project);
		getTodoListsFromTodoSet(todoSet, project, callback);
	}

	/**
	 * get Events - Gets events for Dayback. Lui modified this function to comobine todos with events.
	 * @param  {Number}   projectID  The project id to use to get events.
	 * @param  {Number}   scheduleID The schedule id to use to get events.
	 * @param  {NONE}   queryStart UNUSED.
	 * @param  {NONE}   queryEnd   UNUSED.
	 * @param  {Function} callback   The function to invoke when the process is complete.
	 *
	 */
	function getEvents(
		projectID,
		scheduleID,
		allowTodo,
		queryStart,
		queryEnd,
		initialFetchIDs,
		eventsDeferred,
		callback
	) {
		var eventResult = [];
		var queryCount = allowTodo ? 2 : 1;
		var projectIDString = initialFetchIDs ? initialFetchIDs : projectID;
		var projectString = '&bucket=' + projectIDString;
		var query =
			'/projects/recordings.json?type=Schedule::Entry' + projectString;

		//If this is an initial fetch and we have already fetched events from a list of project IDs return events for just the requested project
		if (eventsPromise && initialFetchIDs) {
			eventsPromise.then(function (events) {
				callback(getProjectEvents(projectID, eventCache));
			});
			return;
		}

		queryBasecamp(query, true, processResult);

		if (allowTodo) {
			getTodos(projectID, initialFetchIDs, function (todos) {
				processResult(todos);
			});
		}

		eventsPromise = eventsDeferred.promise;

		/**
		 * processResult - scoped function which will trigger the combineTodos function and inject todos into the events
		 * @param  {Array} events the events being passed all the way to when we can finally query for todos.
		 *
		 */
		function processResult(events) {
			queryCount--;

			//Make sure we have a valid array
			if (!events || !Array.isArray(events)) {
				events = [];
			}
			for (var i = 0; i < events.length; i++) {
				eventResult.push(events[i]);
			}
			if (queryCount <= 0) {
				eventCache = eventResult;
				callback(getProjectEvents(projectID, eventCache));
				eventsDeferred.resolve();
			}
		}
	}

	function createEvent(projectID, scheduleID, editObj, callback) {
		var path =
			'/buckets/' +
			projectID +
			'/schedules/' +
			scheduleID +
			'/entries.json';

		editBasecamp(path, editObj, 'create', processResult);

		function processResult(result) {
			console.log('result', result);
			if (result) {
				callback(result);
			} else {
				callback(false);
			}
		}
	}

	function setTodoCompletedValue(editObj, mutatedTodo, callback) {
		var path = mutatedTodo.completion_url.substring(
			mutatedTodo.completion_url.indexOf('/buckets/')
		);

		editBasecamp(
			path,
			editObj,
			editObj.completed ? 'create' : 'delete',
			processResult
		);

		function processResult(result) {
			if (result === '' || result.errorCode === 204) {
				mutatedTodo.completed = editObj.completed;
				callback(mutatedTodo);
			} else {
				callback(result);
			}
		}
	}
	/**
	 * updateEvent handles sending the update request to the basecamp api. this function currently uses the app_url property to detect
	 * if it finds todos in the app url it modifies the edit object before it is sent to basecamp via the editBasecamp function. This
	 * function also modifies the result of processResult to return then edited object instead of the result from basecamp.
	 *
	 *
	 * @param  {Number}   projectID The project ID of the updated event / todo
	 * @param  {Number}   eventID   The event ID of the updated event / todo
	 * @param  {Object}   event     The event  being updated. This is used to mergeTodos and check for type.
	 * @param  {Object}   editObj   The updated object.
	 * @param  {Function} callback the callback to invoke when the process is complete. this is being passed until the process is complete.
	 */
	function updateEvent(
		projectID,
		eventID,
		scheduleID,
		editObj,
		event,
		callback
	) {
		var path = '';
		var isTodo = event.todo;
		var todoList = event.parentListID;
		var action = eventID ? 'update' : 'create';

		if (isTodo) {
			/**
			 * @description - here we are modfifying the edit object to comply with Basecamp's requriements for todos. The requirements that a todo must have content
			 * We also modify the edit object to transform Dayback's starts_at and ends_at properties to the starts_on and due_on Basecamp properties.If the starts_on
			 * date equals the due_on date it is a single day todo and we remove the starts_on property.
			 * @type {Object} the edited object for basecamp.
			 */

			editObj.content = editObj.summary;
			editObj.starts_on = moment(editObj.starts_at).format();
			editObj.due_on = moment(editObj.ends_at).format();

			if (editObj.starts_on === editObj.due_on) {
				delete editObj.starts_on;
			}

			path = eventID
				? '/buckets/' + projectID + '/todos/' + eventID + '.json'
				: '/buckets/' +
				  projectID +
				  '/todolists/' +
				  todoList +
				  '/todos.json';
		} else {
			path = eventID
				? '/buckets/' +
				  projectID +
				  '/schedule_entries/' +
				  eventID +
				  '.json'
				: '/buckets/' +
				  projectID +
				  '/schedules/' +
				  scheduleID +
				  '/entries.json';
		}

		editBasecamp(path, editObj, action, processResult);

		/**
		 * processResult - scoped callback to trigger the next function in our chain. This function pases the callback specified in the beginning of our call chain
		 * to the mutateTodo function. the isTodo boolean from the parent functions is used to check the type. If the type is not todo it does not mutate the result.
		 * @param  {object} result the result object from basecamp.
		 *
		 */
		function processResult(result) {
			if (result && isTodo) {
				//Change completion if the new completed value is different from the todo completed value
				if (
					typeof editObj.completed === 'undefined' ||
					editObj.completed === result.completed
				) {
					callback(mutateTodo(result));
				} else {
					setTodoCompletedValue(
						editObj,
						mutateTodo(result),
						callback
					);
				}
			} else if (result) {
				callback(result);
			} else {
				callback(false);
			}
		}
	}

	function deleteEvent(projectID, eventID, editObj, callback) {
		var path =
			'/buckets/' +
			projectID +
			'/recordings/' +
			eventID +
			'/status/trashed.json';

		editBasecamp(path, '', 'update', processResult);

		function processResult(result) {
			if (result) {
				callback(result);
			} else {
				callback(false);
			}
		}
	}

	//Private Functions

	function getAccountFromResult(authorizeResult, product) {
		if (!authorizeResult) {
			return;
		}
		//Find the account that matches the specified product (accounts are basecamp products - basecamp, highrise, campfire etc...)
		if (authorizeResult && authorizeResult.accounts) {
			for (var i = 0; i < authorizeResult.accounts.length; i++) {
				if (authorizeResult.accounts[i].product === product) {
					return authorizeResult.accounts[i];
				}
			}
		}
	}

	function getScheduleFromProject(project) {
		var schedule;
		var todoSet = getTodoSetFromProject(project);
		for (var i = 0; i < project.dock.length; i++) {
			if (project.dock[i].name === 'schedule') {
				//Clone object so we can mutate
				schedule = JSON.parse(JSON.stringify(project.dock[i]));
				//convert id to string
				schedule.projectID = project.id.toString();
				schedule.id = schedule.id.toString();
				schedule.name = project.name;

				//Set urls
				schedule.scheduleURL = schedule.app_url;
				schedule.projectURL = project.app_url;
				schedule.todosetURL = todoSet.app_url;
				return schedule;
			}
		}
	}

	/**
	 * getProjectFromProjects - gets project details from an project Id using the projects array that we retrieved on init
	 * @param  {Number}   id      the project id to match against
	 *
	 */
	function getProjectFromProjects(id) {
		for (var i = 0; i < projects.length; i++) {
			if (projects[i].id === id) {
				return projects[i];
			}
		}
	}

	/**
	 * getProject - gets project details from an project Id. It will call getTodoSetFromProject via an inner scoped function.
	 * @param  {Number}   id      the project id to use in the query
	 * @param  {Array}   events   the events from the schedule query. This is being passed down alongide the callback.
	 * @param  {Function} callback the callback to invoke when the process is complete. this is being passed until the process is complete.
	 *
	 */
	function getProject(id, callback) {
		var query = '/projects/' + id + '.json';
		queryBasecamp(query, false, callback);
	}

	/**
	 * getTodoSetFromProject - This function gets the todoSet from the dock array of a project. It will call getTodoListsFrom Project. This function
	 * will also mutate the todoset by adding the project name and id to it.
	 * @param  {Object}   project The project object to parse toe todoSet from
	 * @param  {Array}   events   the events from the schedule query. This is being passed down alongide the callback.
	 * @param  {Function} callback the callback to invoke when the process is complete. this is being passed until the process is complete.
	 *
	 */
	function getTodoSetFromProject(project, callback) {
		for (var i = 0; i < project.dock.length; i++) {
			if (project.dock[i].name === 'todoset') {
				return project.dock[i];
			}
		}
		return;
	}
	/**
	 * getTodoListsFromTodoSet - This function will query the basecamp api for the todolists associated with the todoset of a project. It will call
	 * getTodoListsFromTodoSet via an inner scoped function when the basecamp query is processed.
	 * @param  {Object}   todoSet The todoSet Object to use in getting the list
	 * @param  {Array}   events   the events from the schedule query. This is being passed down alongide the callback.
	 * @param  {Function} callback the callback to invoke when the process is complete. this is being passed until the process is complete.
	 *
	 */
	function getTodoListsFromTodoSet(todoSet, project, callback) {
		var query =
			'/buckets/' +
			project.id +
			'/todosets/' +
			todoSet.id +
			'/todolists.json';
		queryBasecamp(query, false, processResult);

		/**
		 * processResult - A scoped function. This function will call getTodosFromTodoLists when it is invoked after the basecamp query.
		 * @param  {Array} todoLists an array of todoLists. The response from the Basecamp API
		 *
		 */
		function processResult(todoLists) {
			var query;
			todoLists = todoLists || [];
			var listCount = todoLists.length;
			var processedLists = 0;

			if (listCount > 0) {
				for (var i = 0; i < listCount; i++) {
					query =
						'/buckets/' +
						project.id +
						'/todolists/' +
						todoLists[i].id +
						'/groups.json';
					queryBasecamp(query, false, processGroupResult);
				}
			} else {
				processGroupResult(null);
			}

			function processGroupResult(groupData) {
				var groupsAdded = 0;
				if (groupData) {
					for (var group = 0; group < groupData.length; group++) {
						for (var list = 0; list < todoLists.length; list++) {
							if (
								groupData[group].parent.id ===
								todoLists[list].id
							) {
								groupData[group].isGroup = true;
								todoLists.splice(
									list + 1 + groupsAdded,
									0,
									groupData[group]
								);
								groupsAdded++;
							}
						}
					}
				}
				processedLists++;
				if (processedLists >= listCount) {
					//Add the todo lists to the project
					project.lists = todoLists;
					callback(project.lists);
				}
			}
		}
	}

	function applyTodoGroupsToLists(todoLists, callback) {
		var todoListResult = [];
		var processCount = 0;
		for (var i = 0; i < todoLists.length; i++) {
			todoListResult.push(todoLists[i]);
			var query =
				'/buckets/' +
				todoLists[i].bucket.id +
				'/todolists/' +
				todoLists[i].id +
				'/groups.json';
			queryBasecamp(query, false, applyTodoGroups);
		}

		function applyTodoGroups(result) {
			processCount++;
			if (result) {
				for (var i = 0; i < result.length; i++) {
					todoListResult.push(result[i]);
				}
			}

			if (processCount >= todoLists.length) {
				callback(todoListResult);
			}
		}
	}

	/**
	 * getTodosFromTodoList - This function will iterate through a todoList array and query the Basecamp API for each todoList it iterates through.
	 * The inner scoped function pushTodos is invoked with the response for each query. An inner scoped function of that function will finally invoke
	 * the passed callback when getTodosFromTodoList has iterated through each todoList.
	 * @param  {Array}    todoLists An array of todolists to use when querying the basecamp api.
	 * @param  {Array}    events    The events from the schedule query. This is being passed down alongide the callback.
	 * @param  {Function} callback  The callback to invoke when the process is complete. this is being passed until the process is complete.
	 */
	function getTodosFromTodoLists(todoLists, callback) {
		var todosResult = [];
		var listPosition = 0;
		for (var i = 0; i < todoLists.length; i++) {
			var query =
				'/buckets/' +
				todoLists[i].bucket.id +
				'/todolists/' +
				todoLists[i].id +
				'/todos.json';
			queryBasecamp(query, false, pushTodos);
		}

		/**
		 * pushTodos - scoped function. This function pushes todos into the event array. This function sets a variable called listPosition which is
		 * used in an inner function trigger our callback return. This function is also mutating the todo event using the mutateTodo function.
		 * @param  {Array} todos An array of todos to push to the event array.
		 *
		 */
		function pushTodos(todos) {
			//Make sure we are working with an array if todos is not set
			if (!todos) {
				todos = [];
			}
			for (var i = 0; i < todos.length; i++) {
				var todo = todos[i];
				if (todo.due_on) {
					todo.lists = todoLists;
					todosResult.push(mutateTodo(todo));
				}
			}
			listPosition++;
			if (listPosition >= todoLists.length) {
				callback(todosResult);
			}
		}
	}

	function authorizeBasecamp(callback) {
		var url = baseURL + queryURL + '?authorize=true';
		var result;
		var request = new XMLHttpRequest();
		request.open('POST', url);
		request.onreadystatechange = function () {
			if (request.readyState === 4 && request.status === 200) {
				result = JSON.parse(request.responseText);
				callback(result);
			}
		};
		request.send(settings.token);
	}

	function refreshAccessToken(callback) {
		var url = queryURL + '?refresh=true';
		var request = new XMLHttpRequest();
		request.open('POST', url);
		request.onreadystatechange = function () {
			if (request.readyState === 4 && request.status === 200) {
				//Send raw response as we will json encode in the callback
				callback(request.responseText);
			}
		};
		request.send(settings.refreshToken);
	}

	function queryBasecamp(query, hasQueryParam, callback) {
		//Non error query result is json containing both an ETag property and a data property
		var queryParamString = hasQueryParam ? 'hasParams=true&' : '';
		var url =
			baseURL +
			queryURL +
			'?query=true&' +
			queryParamString +
			'url=' +
			encodeURIComponent(settings.account.href + query);
		var result;
		var request = new XMLHttpRequest();
		request.open('POST', url);
		request.onreadystatechange = function () {
			if (request.readyState === 4) {
				if (request.status === 200) {
					result = JSON.parse(request.responseText);
					var rateLimit = JSON.parse(result.rateLimit);

					if (result && result.errorCode == 429) {
						var rateLimitDelay =
							new Date(rateLimit.until).getTime() -
							new Date().getTime();

						if (!rateLimitReached) {
							rateLimitReached = true;
							errorReporter(
								null,
								'Basecamp API rate limit reached. One moment as Basecamp catches up.'
							);
						}

						window.setTimeout(function () {
							rateLimitReached = false;
							queryBasecamp(query, hasQueryParam, callback);
						}, rateLimitDelay);
					} else {
						callback(result.data);
					}
				} else {
					errorReporter();
					callback(result);
				}
			}
		};
		request.send(settings.token);
	}

	function editBasecamp(path, data, type, callback) {
		//Type can be 'create', 'update', or 'delete'
		var part1 = btoa(settings.token);
		data = part1 + '.' + JSON.stringify(data);
		var url =
			queryURL +
			'?' +
			type +
			'=true&url=' +
			encodeURIComponent(settings.account.href + path);
		var result;
		var request = new XMLHttpRequest();
		request.open('POST', url);
		request.onreadystatechange = function () {
			if (request.readyState === 4 && request.status === 200) {
				result = request.responseText
					? JSON.parse(request.responseText)
					: '';
				callback(result);
			}
		};
		request.send(data);
	}

	function ajaxRequest(file, success, error) {
		var xmlhttp;

		if (window.XMLHttpRequest) {
			// code for IE7+, Firefox, Chrome, Opera, Safari
			xmlhttp = new XMLHttpRequest();
		} else {
			// code for IE6, IE5
			xmlhttp = new ActiveXObject('Microsoft.XMLHTTP');
		}

		xmlhttp.onreadystatechange = function () {
			if (xmlhttp.readyState == 4) {
				if (xmlhttp.status == 200) {
					success(xmlhttp.responseText, xmlhttp.status);
				} else {
					error(xmlhttp.responseText, xmlhttp.status);
				}
			}
		};

		xmlhttp.open('GET', file, true);
		xmlhttp.send();
	}
	/**
	 * mutateTodo clones the todo variable and modifies the propertys to match items in the basecamp fieldmap in sources-services.js.
	 * Once mutatation is complete the supplied callback is called with the mutated Todo. The callback is necessary due to this function being
	 * called in the updateEvent callchain. We need to prevent a race condition in that case.
	 * @param  {Object}   todo     The basecamp todo to transform into a Dayback todo.
	 * @param  {Function} callback  The callback to invoke when the process is complete. this is being passed until the process is complete.
	 */
	function mutateTodo(todo, callback) {
		/**
		 * Some minor mutations to Todo Schema to match Dayback event schema per conversation with Tanner.
		 *
		 * See todo schema at: https://github.com/basecamp/bc3-api/blob/master/sections/todos.md#to-dos
		 *
		 * The ternaries here deal with due dates or or Todo duration. If startDate is not null it is a
		 * multi day Todo.
		 *
		 */
		var mutatedTodo = JSON.parse(JSON.stringify(todo));
		var dueDate = moment(todo.due_on).isValid()
			? moment(todo.due_on).format()
			: null;
		var startDate = moment(todo.starts_on).isValid()
			? moment(todo.starts_on).format()
			: null;

		mutatedTodo.starts_at = startDate !== null ? startDate : dueDate;
		mutatedTodo.ends_at = dueDate !== null ? dueDate : startDate;
		mutatedTodo.type = todo.type;
		mutatedTodo.all_day = true;
		mutatedTodo.summary = todo.content;
		mutatedTodo.description = todo.description;
		mutatedTodo.participants = todo.assignees;
		mutatedTodo.parentListID = todo.parent ? todo.parent.id : null;
		mutatedTodo.parentListName = todo.parent ? todo.parent.title : null;
		mutatedTodo.completed = todo.completed;

		return mutatedTodo;
	}

	function getProjectEvents(projectID, events) {
		var event;
		var eventResult = [];
		projectID = Number(projectID); //basecamp id's are numbers so == to invoke coersion if necessary
		for (var i = 0; i < events.length; i++) {
			event = events[i];
			if (event.bucket && event.bucket.id === projectID) {
				eventResult.push(event);
			}
		}
		return eventResult;
	}

	function clearEventCache() {
		eventCache = null;
		eventsPromise = null;
	}

	// function refreshAccessToken( callback ) {
	// 	var result;
	// 	var request = new XMLHttpRequest();
	// 	request.open('POST',storage.phpPath + '?refresh=true');
	// 	request.onreadystatechange = function(){
	// 		if(request.readyState===4 && request.status===200) {
	// 			result = request.responseText;
	// 			result=JSON.parse(result);
	// 			if(result.access_token) {
	// 				storage.access_token = result.access_token;
	// 				callback(request.responseText);
	// 			}
	// 			else {
	// 				callback(false);
	// 				}
	// 			}
	// 		};
	// 	request.send(storage.refresh_token);
	// }

	function retryCheck(result) {
		if (!result) {
			return;
		}
		var error = result.error;
		var errorResult;
		var reAuth;
		var message;
		var preventDeauth;

		if (error) {
			reAuth = false; //error.code === 'authorization_expired';
			message = error.message;
			preventDeauth = !reAuth;

			errorResult = {
				reAuth: reAuth,
				preventDeauth: preventDeauth,
				code: error ? error.code : null,
				message: message ? message : '',
			};
		}

		return errorResult;
	}

	function ajaxRequest(options, retryCount) {
		// parameter object options:
		//{url, type, params, data, retryCheck, preventErrorReporter, onSuccess, onError}

		var responseResult;
		var paramList = [];
		var data;
		var type;
		var retryCheckResult;
		var url = options.url;
		var code;
		var message;
		var preventDeauth;
		var retryLimit = options.hasOwnProperty('retryLimit')
			? options.retryLimit
			: settings.retryLimit;

		if (!options) {
			return;
		}

		if (!retryCount) {
			retryCount = 0;
		}

		// Build parameter string and append to url
		if (options.params) {
			for (var param in options.params) {
				if (param !== 'access_token') {
					paramList.push(
						encodeURIComponent(param) +
							'=' +
							encodeURIComponent(options.params[param])
					);
				}
			}

			url += paramList.length ? '?' + paramList.join('&') : '';
		}

		type = options.type ? options.type.toUpperCase() : 'GET';

		var xhr = new XMLHttpRequest();
		xhr.open(type, url);

		if (options.params && options.params.access_token) {
			xhr.setRequestHeader(
				'Authorization',
				'Bearer ' + options.params.access_token
			);
		}

		if (options.data) {
			try {
				data = JSON.stringify(options.data);
				xhr.setRequestHeader('Content-Type', 'application/json');
			} catch (error) {
				// Could perform an action if options is not an object
				data = options.data;
				xhr.setRequestHeader(
					'Content-Type',
					'application/x-www-form-urlencoded'
				);
			}
		}

		xhr.onreadystatechange = function (e) {
			if (xhr.readyState == 4) {
				try {
					responseResult = JSON.parse(xhr.response);
				} catch (error) {
					responseResult = xhr.response;
				}

				if (
					xhr.status == 200 ||
					(type === 'DELETE' && xhr.status == 204)
				) {
					if (
						options.onSuccess &&
						(xhr.response ||
							(type === 'DELETE' && xhr.status == 204))
					) {
						retryCheckResult = options.retryCheck
							? options.retryCheck(responseResult)
							: null;

						if (retryCheckResult && retryCount < retryLimit) {
							if (retryCheckResult.reAuth) {
								authAndRetry();
							} else {
								retry();
							}

							return;
						}

						options.onSuccess(responseResult);
					}
				} else {
					retryCheckResult = options.retryCheck
						? options.retryCheck(responseResult)
						: null;
					preventDeauth =
						retryCheckResult && retryCheckResult.preventDeauth;

					// Check for an invalid token response and attempt to renew the token. Limit to 3 retries
					if (
						((retryCheckResult && retryCheckResult.reAuth) ||
							(options.params.access_token &&
								!retryCheckResult &&
								(Number(xhr.status) === 401 ||
									Number(xhr.status) === 403))) &&
						retryCount < retryLimit &&
						userTokenMap[settings.token]
					) {
						authAndRetry();
					} else if (retryCheckResult && retryCount < retryLimit) {
						retry();
					} else if (options.onError) {
						if (
							(options.preventErrorReporter && !preventDeauth) ||
							!options.preventErrorReporter
						) {
							errorReporter(
								xhr.status,
								retryCheckResult && retryCheckResult.message,
								preventDeauth
							);
						}
						options.onError(
							retryCheckResult || xhr.response,
							xhr.status,
							preventDeauth
						);
					}
				}
			}
		};
		xhr.send(data);

		function authAndRetry() {
			window.setTimeout(function () {
				updateToken(
					userTokenMap[settings.token].userID,
					userTokenMap[settings.token].sourceID,
					function (token) {
						options.params.access_token = token;
						ajaxRequest(options, retryCount + 1);
					}
				);
			}, settings.retryWait);
		}

		function retry() {
			window.setTimeout(function () {
				ajaxRequest(options, retryCount + 1);
			}, settings.retryWait);
		}
	}

	function getURLParams(url) {
		if (!url) {
			return {};
		}
		var match,
			pl = /\+/g, // Regex for replacing addition symbol with a space
			search = /([^&=]+)=?([^&]*)/g,
			decode = function (s) {
				return decodeURIComponent(s.replace(pl, ' '));
			},
			query = url.substring(1);

		var urlParams = {};
		while ((match = search.exec(query))) {
			urlParams[decode(match[1])] = decode(match[2]);
		}
		return urlParams;
	}

	function isStandalone() {
		var standalone =
			'standalone' in window.navigator && window.navigator.standalone;
		return standalone;
	}

	function isIframe() {
		try {
			return window.self !== window.top;
		} catch (e) {
			return true;
		}
	}

	function isMobile() {
		return isIos() || isAndroid() || isWindowsPhone();
	}

	function isIos() {
		var userAgent = window.navigator.userAgent.toLowerCase();
		return /iphone|ipod|ipad/.test(userAgent) && !window.MSStream;
	}

	function isAndroid() {
		var userAgent = window.navigator.userAgent.toLowerCase();
		return /android/i.test(userAgent);
	}

	function isWindowsPhone() {
		var userAgent = window.navigator.userAgent.toLowerCase();
		return /windows phone/i.test(userAgent);
	}
})();
